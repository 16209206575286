import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Use the SubNav component for navigation on a dashboard-type interface with another set of navigation components above it. This helps distinguish navigation hierarchy.`}</p>
    <p>{`To use SubNav with `}<a parentName="p" {...{
        "href": "https://github.com/ReactTraining/react-router"
      }}>{`react-router`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/react-router-dom"
      }}>{`react-router-dom`}</a>{`, pass
`}<inlineCode parentName="p">{`as={NavLink}`}</inlineCode>{` and omit the `}<inlineCode parentName="p">{`selected`}</inlineCode>{` prop.
This ensures that the NavLink gets `}<inlineCode parentName="p">{`activeClassName='selected'`}</inlineCode></p>
    <p><strong parentName="p">{`Attention:`}</strong>{` Make sure to properly label your `}<inlineCode parentName="p">{`SubNav`}</inlineCode>{` with an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to provide context about the type of navigation contained in `}<inlineCode parentName="p">{`SubNav`}</inlineCode>{`.`}</p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SubNav aria-label="Main">
  <SubNav.Links>
    <SubNav.Link href="#home" selected>
      Home
    </SubNav.Link>
    <SubNav.Link href="#documentation">Documentation</SubNav.Link>
    <SubNav.Link href="#support">Support</SubNav.Link>
  </SubNav.Links>
</SubNav>
`}</code></pre>
    <h2>{`SubNav with search example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SubNav aria-label="Main">
  <SubNav.Links>
    <SubNav.Link href="#home" selected>
      Home
    </SubNav.Link>
    <SubNav.Link href="#documentation">Documentation</SubNav.Link>
    <SubNav.Link href="#support">Support</SubNav.Link>
  </SubNav.Links>

  <TextInput type="search" icon={Search} width={320} />
</SubNav>
`}</code></pre>
    <h2>{`SubNav with filtered search example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SubNav aria-label="Main">
  <FilteredSearch>
    <Dropdown title="Filter">
      <Dropdown.Menu direction="sw">
        <Dropdown.Item>
          <a href="#">Item 1</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="#">Item 2</a>
        </Dropdown.Item>
        <Dropdown.Item>
          <a href="#">Item 3</a>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <TextInput type="search" icon={Search} width={320} />
  </FilteredSearch>
  <SubNav.Links>
    <SubNav.Link href="#home" selected>
      Home
    </SubNav.Link>
    <SubNav.Link href="#documentation">Documentation</SubNav.Link>
    <SubNav.Link href="#support">Support</SubNav.Link>
  </SubNav.Links>
</SubNav>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`SubNav and SubNav.Link components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h3>{`SubNav`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`actions`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Node`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Place another element, such as a button, to the opposite side of the navigation items.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use `}<inlineCode parentName="td">{`right`}</inlineCode>{` to have navigation items aligned right.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`full`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to make navigation fill the width of the container.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`aria-label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to set the `}<inlineCode parentName="td">{`aria-label`}</inlineCode>{` on the top level `}<inlineCode parentName="td">{`<nav>`}</inlineCode>{` element.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`SubNav.Link`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URL to be used for the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Used to style the link as selected or unselected`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      